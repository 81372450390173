<template>
    <div>
        <el-button type="text" @click="open" class="btn"></el-button>
        <div class="img" v-if="showImage">
            <img src="../assets/2.jpg" alt="" class="high-quality-image" />
        </div>
        <div class="div" v-if="showText">
            随地大小便还想休仙，不知天高地厚！
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeView",
    data() {
        return {
            showImage: true,
            showText: false
        };
    },
    methods: {
        open() {
            this.$alert('欲练神功，需先拜祖师！！！', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `注视祖师五秒`
                    });
                    setTimeout(() => {
                        this.showImage = false;
                        this.showText = true; // 5秒后显示文字
                    }, 5000); // 5秒后隐藏图片并显示文字
                }
            });
        }
    },
    mounted() {
        this.open(); // 页面加载时自动弹出消息框
    }
}
</script>

<style scoped lang="less">
.btn {
    display: none;
}
.img {
    display: flex;
    justify-content: center;
    // margin-top: 20px;
}
.high-quality-image {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1440px;
    object-fit: cover;
    transition: transform 0.3s ease;
}
.high-quality-image:hover {
    transform: scale(1.5); /* 鼠标悬停时放大图片 */
}
.div {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #333;
}

/* 移动端样式 */
@media (max-width: 600px) {
    .img {
        width: 100vw; /* 占满视口宽度 */
        height: 100vh; /* 占满视口高度 */
        margin-top: 0; /* 移除上边距 */
        overflow: hidden; /* 隐藏溢出部分 */
    }
    .high-quality-image {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        transform: none; /* 移除放大效果 */
    }
}
</style>