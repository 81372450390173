<template>
    <div id="app">
      <!-- <LoginView /> -->
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  // import LoginView from "./components/LoginView.vue";
  
  export default {
    name: "app",
    components: {
      // LoginView,
    },
  };
  </script>
  
  <style>
  /* 添加视口元标签 */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  
  html {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    background-color: #f8f9fa;
  }
  
  /* 使用媒体查询进行移动端适配 */
  @media (max-width: 600px) {
    html {
      font-size: 14px;
    }
  }
  </style>