<template>
    <div>
        <div class="card-content">
            <div class="top-left">修仙秘籍</div>
            <el-card>
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                    class="demo-ruleForm">
                    <div class="form-title">
                        <div :class="{ active: isLogin, Login: true }" @click="toggleLogin(true)">
                            登陆
                        </div>
                        /
                        <div :class="{ active: !isLogin, Register: true }" @click="toggleLogin(false)">
                            注册
                        </div>
                    </div>
                    <div class="login-content" v-if="isLogin">
                        <el-form-item label="账号" prop="account">
                            <el-input v-model.number="ruleForm.account"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="pass">
                            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">登陆</el-button>
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                        </el-form-item>
                    </div>

                    <div class="register-content" v-if="!isLogin">
                        <el-form-item label="输入账号" prop="account">
                            <el-input v-model.number="ruleForm.account"></el-input>
                        </el-form-item>
                        <el-form-item label="输入密码" prop="pass">
                            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">注册</el-button>
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginView",

    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("账号不能为空"));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error("请输密码"));
                }
            }, 1000);
        };
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                account: "xiaoLi",
                pass: "admin",
            },
            rules: {
                pass: [{ validator: validatePass, trigger: "blur" }],
                checkPass: [{ validator: validatePass2, trigger: "blur" }],
                account: [{ validator: checkAge, trigger: "blur" }],
            },
            isLogin: true,
        };
    },
    methods: {
        toggleLogin(isLogin) {
            this.isLogin = isLogin;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        submitForm() {
            this.$message({
                message: "登陆成功",
                type: "success",
            });
            setTimeout(() => {
                this.$router.push("/home");
            }, 1000);
        },
    },
};
</script>

<style scoped lang="less">
.card-content {
    background-image: url("../assets/1.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.Login,
.Register {
    margin: 0 5px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s;
}

.Login:hover,
.Register:hover {
    color: #409eff;
    /* 鼠标悬停时字体颜色变化 */
}

.Login::after,
.Register::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: #409eff;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.Login:hover::after,
.Register:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.active {
    font-weight: bold;
    color: #409eff;
}

.el-form-item__label {
    margin-right: 10px;
    /* 调整label和输入框之间的间距 */
}

.top-left {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    /* 可选：增加一些内边距 */
    //   background-color: rgba(255, 255, 255, 0.8); /* 可选：增加背景色以提高可读性 */
    font-size: 5vw;
    font-family: "KaiTi", "楷体", serif;
    user-select: none;
    // 鼠标小手
    cursor: pointer;
    /* 设置字体为楷体 */
    background: -webkit-linear-gradient(145deg,
            #2eaf8d,
            #2f6ae6 5%,
            #347ba6 10%,
            #16d225 15%,
            #4cc410 20%,
            #bb2c10 25%,
            #2f63a4 30%,
            #28dfd4 35%,
            #ff0000 40%,
            #00ff00 45%,
            #0000ff 50%);
    background: -webkit-linear-gradient(30deg,
            #ff0000,
            #00ff00 55%,
            #0000ff 60%,
            #ffff00 70%,
            #ff00ff 80%,
            #ff0000 90%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 200% 100%;
    animation: flowCss 5s infinite linear;
    text-align: center;
    font-weight: bold;
}

@keyframes flowCss {
    0% {
        /* 移动背景位置 */
        background-position: 0 0;
    }

    100% {
        background-position: -400% 0;
    }
}
</style>
